<template>
  <v-app class="add--vehicle">
    <v-dialog v-model="dialogVehicle" width="900px">
      <v-card>
        <v-container class="text-center">
          <v-card-title>
            <v-row class="mt-n5">
              <v-col class="mt-n10" col="12" md="2">
                <v-img
                  src="@/assets/img/search.png"
                  class="mx-auto my-5"
                  width="70"
                />
              </v-col>
              <v-col class="text-left" col="12" md="10">
                <h3>Vehicle Verification</h3>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-title>
            <h3 class="primary--text">Verified Vehicle & User Details</h3>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <h3 class="text-left grey--text pt-5">Qualified For Assessment</h3>
          </v-card-text>
          <v-card-text>
            <ul class="text-left">
              <v-row>
                <v-col cols="12" md="6">
                  <li>
                    Insured’s Name:<span class="pl-3 primary--text">
                      {{ scanObject(vehicleDetails, 'client.name') }}
                    </span>
                  </li>
                  <li>
                    Reg No:
                    <span class="pl-3 primary--text">{{
                      scanObject(vehicleDetails, 'vehicle.registrationNumber')
                    }}</span>
                  </li>
                  <li>
                    Policy No:
                    <span class="pl-3 primary--text">{{
                      scanObject(vehicleDetails, 'policy.policyNumber')
                    }}</span>
                  </li>
                  <li>
                    Sum Assured:
                    <span class="pl-3 primary--text">{{
                      scanObject(vehicleDetails, 'policy.sumInsured')
                    }}</span>
                  </li>
                  <li>
                    Excess Payable:
                    <span class="pl-3 primary--text">{{
                      scanObject(vehicleDetails, 'vehicleDetails.excessPayable')
                    }}</span>
                  </li>
                </v-col>
                <v-col cols="12" md="6">
                  <li>
                    Policy Type:<span class="pl-3 primary--text">{{
                      scanObject(vehicleDetails, 'policy.coverType')
                    }}</span>
                  </li>
                  <li>
                    Policy Description:<span class="pl-3 primary--text">{{
                      scanObject(vehicleDetails, 'policy.description')
                    }}</span>
                  </li>
                  <li>
                    Policy Period from:
                    <span class="pl-3 primary--text">{{
                      scanObject(vehicleDetails, 'policy.effectiveFromDate')
                    }}</span>
                  </li>
                  <li>
                    Policy Period to:
                    <span class="pl-3 primary--text">{{
                      scanObject(vehicleDetails, 'policy.effectiveToDate')
                    }}</span>
                  </li>
                </v-col>
              </v-row>
            </ul>
          </v-card-text>
          <v-card-actions class="vehicle--search">
            <form
              class="mx-auto mb-5 vehicle--search__input"
              @submit.prevent="addVehicleDetails"
            >
              <v-btn
                id="addVehicleBtn"
                class="secondary my-5"
                block
                large
                @click="addVehicleDetails"
              >
                Add Vehicle
              </v-btn>
            </form>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
    <v-snackbar top right v-model="snackbar" :color="snackbarColor">
      {{ snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapState } from 'vuex'
import axios from 'axios'
import validation from '@/utils/Validations'
import store from '@/store'

export default {
  props: {
    dialogVehicle: {
      type: Boolean,
      required: true,
    },
    vehicleDetails: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    valid: false,
    regNo: '',
    snackbar: false,
    snackbarMessage: '',
    snackbarColor: '',
    dialog: false,
    dialogInvite: false,
    ...validation,
  }),

  computed: {
    ...mapState(['loading']),
  },

  methods: {
    addVehicleDetails() {
      this.$emit('updateDialogVehicleStatus', false)
      let vehicleDetailsPayload = this.vehicleDetails
      vehicleDetailsPayload.region =
        store.getters['auth/authenticatedUser']['custom:region']
      axios
        .post(
          `/motor-assessment/api/booking?userId=${this.$store.state.auth.user.sub}`,
          vehicleDetailsPayload
        )
        .then((res) => {
          if (res.data.success === true) {
            this.snackbar = true
            this.snackbarMessage = 'This vehicle was added successfully'
            this.snackbarColor = 'success'
            location.reload()
          } else {
            this.snackbar = true
            this.snackbarMessage = res.data.msg
            this.snackbarColor = 'error'
          }
        })
        .catch(() => {
          this.snackbar = true
          this.snackbarMessage = 'Oops! Something went wrong!'
          this.snackbarColor = 'error'
        })
    },
  },
}
</script>
<style scoped>
.add--vehicle {
  height: 0;
  float: right;
}

.vehicle--search__input {
  width: 300px;
}
</style>
